.LinkList {
    font-family: 'Ubuntu Mono';
    font-size: 20pt;
    font-weight: bold;
}

.LinkList-bottom {
    position: fixed;
    right: 20px;
    bottom: 20px;
}

.LinkList-bottom ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.LinkList-bottom ul li {
    margin-top: 40px;
    display: block;
    text-align: right;
    cursor: pointer;
}

.LinkList-left {
    position: fixed;
    left: 30px;
    top: calc(95vw);
}

.LinkList-left ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.LinkList-left ul li {
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 20px;
}

.LinkList-right {
    position: fixed;
    left: calc(100vh);
    top: 30px;
}

.LinkList-right ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.LinkList-right ul li {
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 20px;
}

.LinkList-link {
    background-color: #ffffff;
    display: inline-block;
    padding: 2px;
    text-align: right;
    color: #000000;
}

.LinkList-link:hover {
    background-color: #000000;
    color: #ffffff;
}