/*BODY {
  will-change: opacity;
}*/

.no-flick { 
  -webkit-transform:translate3d(0,0,0);
}


.App-background-image {
  background-image: url(img01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  transition: background 1s linear;
  -moz-transition: background 1s linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}