@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Condensed');


.NameBox {
  position: fixed;
  border: none;
  /*background-color: rgba(255, 255, 255, 0.6);*/
  z-index: 10;
  left: 30px;
  top: 30px;
  /*will-change: opacity;*/
}

.NameBox-big {
    width: 402px;
    height: 440px;
    font: bold 75pt 'Playfair Display', monospace;
    padding-left: 28px;
}

.NameBox-small {
    /*width: 350px;*/
    width: calc(95vw - 60px);
    /*height: 350px;*/
    height: calc(95vw - 60px);
    font: bold 18.5vw 'Playfair Display', monospace;
    padding-left: 5vw;
}

.NameBox-small-landscape {
    width: calc(95vh - 60px);
    height: calc(100vh - 60px);
    font: bold 18.5vh 'Playfair Display', monospace;
    padding-left: 5vh;
}

.NameBox-text {
    text-align: left;
    margin: 0;
    text-justify: inter-character;
}

.NameBox-text p {
    margin-top: 0px;
    margin-bottom: -25px;
    /*margin-left: 5vw;*/
    margin-left: 0px;
    width: 100%;
}

.NameBox-smalltext {
    font-size: 20pt;
    padding-top: 32px;
    font-family: 'Ubuntu Condensed';
}

.NameBox-smalltext-mobile {
    /*font-size: 20pt;*/
    font-size: 6vw;
    padding-top: 32px;
    font-family: 'Ubuntu Condensed';
}

.NameBox-smalltext-mobile-landscape {
    /*font-size: 20pt;*/
    font-size: 6vh;
    padding-top: 32px;
    font-family: 'Ubuntu Condensed';
}

.NameBox-lighten {
	color: black;
	/*mix-blend-mode: lighten;*/
	background-color: rgba(255, 255, 255, 0.9);
}