@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700');

.ImageCaption {
    position: fixed;
    top: 30px;
    right: 20px;
    font-family: 'Ubuntu Mono';
    color: #000000;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 14pt;
    font-weight: bold;
    padding: 2px;
    max-width: calc(100vw - 520px);
    text-align: right;
}

.ImageCaption p {
    margin-top: 0px;
    margin-bottom: 0px;
}