@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700');

.Copyright {
    position: fixed;
    font-family: 'Ubuntu Mono';
    color: #ffffff;
    font-size: 14pt;
    font-weight: bold;
}

.Copyright-bottom-left {
    bottom: 20px;
    left: 20px;
}

.Copyright-bottom-right {
    bottom: 30px;
    right: 20px;
    max-width: calc(100vw - 100vh);
    text-align: right;
}

.Copyright p {
    margin-top: 0px;
    margin-bottom: 0px;
}