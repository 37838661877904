@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Condensed);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*BODY {
  will-change: opacity;
}*/

.no-flick { 
  -webkit-transform:translate3d(0,0,0);
}


.App-background-image {
  background-image: url(/static/media/img01.6dc97a41.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  transition: background 1s linear;
  -moz-transition: background 1s linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.NameBox {
  position: fixed;
  border: none;
  /*background-color: rgba(255, 255, 255, 0.6);*/
  z-index: 10;
  left: 30px;
  top: 30px;
  /*will-change: opacity;*/
}

.NameBox-big {
    width: 402px;
    height: 440px;
    font: bold 75pt 'Playfair Display', monospace;
    padding-left: 28px;
}

.NameBox-small {
    /*width: 350px;*/
    width: calc(95vw - 60px);
    /*height: 350px;*/
    height: calc(95vw - 60px);
    font: bold 18.5vw 'Playfair Display', monospace;
    padding-left: 5vw;
}

.NameBox-small-landscape {
    width: calc(95vh - 60px);
    height: calc(100vh - 60px);
    font: bold 18.5vh 'Playfair Display', monospace;
    padding-left: 5vh;
}

.NameBox-text {
    text-align: left;
    margin: 0;
    text-justify: inter-character;
}

.NameBox-text p {
    margin-top: 0px;
    margin-bottom: -25px;
    /*margin-left: 5vw;*/
    margin-left: 0px;
    width: 100%;
}

.NameBox-smalltext {
    font-size: 20pt;
    padding-top: 32px;
    font-family: 'Ubuntu Condensed';
}

.NameBox-smalltext-mobile {
    /*font-size: 20pt;*/
    font-size: 6vw;
    padding-top: 32px;
    font-family: 'Ubuntu Condensed';
}

.NameBox-smalltext-mobile-landscape {
    /*font-size: 20pt;*/
    font-size: 6vh;
    padding-top: 32px;
    font-family: 'Ubuntu Condensed';
}

.NameBox-lighten {
	color: black;
	/*mix-blend-mode: lighten;*/
	background-color: rgba(255, 255, 255, 0.9);
}
.Copyright {
    position: fixed;
    font-family: 'Ubuntu Mono';
    color: #ffffff;
    font-size: 14pt;
    font-weight: bold;
}

.Copyright-bottom-left {
    bottom: 20px;
    left: 20px;
}

.Copyright-bottom-right {
    bottom: 30px;
    right: 20px;
    max-width: calc(100vw - 100vh);
    text-align: right;
}

.Copyright p {
    margin-top: 0px;
    margin-bottom: 0px;
}
.ImageCaption {
    position: fixed;
    top: 30px;
    right: 20px;
    font-family: 'Ubuntu Mono';
    color: #000000;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 14pt;
    font-weight: bold;
    padding: 2px;
    max-width: calc(100vw - 520px);
    text-align: right;
}

.ImageCaption p {
    margin-top: 0px;
    margin-bottom: 0px;
}
.LinkList {
    font-family: 'Ubuntu Mono';
    font-size: 20pt;
    font-weight: bold;
}

.LinkList-bottom {
    position: fixed;
    right: 20px;
    bottom: 20px;
}

.LinkList-bottom ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.LinkList-bottom ul li {
    margin-top: 40px;
    display: block;
    text-align: right;
    cursor: pointer;
}

.LinkList-left {
    position: fixed;
    left: 30px;
    top: calc(95vw);
}

.LinkList-left ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.LinkList-left ul li {
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 20px;
}

.LinkList-right {
    position: fixed;
    left: calc(100vh);
    top: 30px;
}

.LinkList-right ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.LinkList-right ul li {
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 20px;
}

.LinkList-link {
    background-color: #ffffff;
    display: inline-block;
    padding: 2px;
    text-align: right;
    color: #000000;
}

.LinkList-link:hover {
    background-color: #000000;
    color: #ffffff;
}
